<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/locations">Standorte</router-link></li>
              <li>{{ location.name }}</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/locations" class="button button-grey button-small">Alle Standorte ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/locations/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/locations/'+this.$route.params.id+'/hours'">Öffnungszeiten</router-link></li>
            <li class="active"><router-link :to="'/admin/locations/'+this.$route.params.id+'/users'">Mitarbeiter</router-link></li>
          </ul>

          <div class="admin-body">

            <form @submit="create_user" class="form" method="post">

              <div class="form-wrap select-wrap">
                <label for="street_number">Benutzer</label>
                <select v-model="user.user_id" class="select-wrap">
                  <option v-for="user in users" v-bind:value="user.id" v-bind:key="user.id">
                    {{ user.first_name }} {{ user.last_name }} ({{ user.email }})
                  </option>
                </select>
              </div>

              <p>
                <input type="submit" value="User hinzufügen ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      location: {},
      users: [],
      user: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.location = response.data.location;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/customers?role=staff', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.users = response.data.customers;
      })
      .catch(error => {
        console.log(error);
      })

    },
    create_user: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id+'/users', {
        'user_id': this.user.user_id
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Mitarbeiter erfolgeich hinzugefügt.',
        });
        this.$router.push('/admin/locations/'+this.$route.params.id+'/users');
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
